/* global _api */

import React, { Component } from "react";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import ExpertUploadMultipleComponent from "components/FormInputs/UploadMultipleComponent";
import Select from "react-select";
import moment from 'moment';
import 'moment/locale/pl';
import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel
} from "react-bootstrap";

import bgImage from "assets/img/expert/cover-form.jpg";
import logo from "assets/img/expert/logo-dark.png";

moment.locale('pl');

class ExpertForm extends Component {

    constructor(props) {
        super(props);

        this.regardingToOptions = [
            {
                "value": "goods",
                "label": "Towaru"
            },
            {
                "value": "service",
                "label": "Usługi"
            }
        ];

        this.state = {
            regardingTo: this.regardingToOptions[0],
            goodsName: "",
            value: "",
            orderIdent: props.match.params.orderIdent || "",
            firstName: "",
            lastName: "",
            emailAddress: "",
            mobilePhone: "",
            comment: "",
            consent1: false,
            additionalFiles: [],
            isLoading: false,
            isSubmitted: false
        };

    }

    componentDidMount() {
        const urlString = window.location.href
        const url = new URL(urlString);
        const orderIdent = url.searchParams.get("orderIdent");
        if (orderIdent) {
            this.setState({ orderIdent: orderIdent });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        let self = this;
        self.props.showNoty('tr', 'info', 'Zapisywanie danych..');
        self.setState({ isLoading: true });
        _api.request(`/external/create-complaint`,
            {
                "files": self.state.additionalFiles,
                "complaint": {
                    "type": self.state.regardingTo.value,
                    "value": self.state.value,
                    "comment": self.state.comment,
                    "consent": self.state.consent1,
                    "goods": self.state.goodsName,
                    "source": "webform"
                },
                "consumer": {
                    "firstname": self.state.firstName,
                    "lastname": self.state.lastName,
                    "emailAddress": self.state.emailAddress,
                    "mobilePhone": self.state.mobilePhone
                },
                "order": {
                    "ident": self.state.orderIdent,
                }
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Dane zostały zapisane');
                self.setState({ isSubmitted: true, isLoading: false });
            },
            (error) => {
                if (error === "E_ORDER_NOT_FOUND") {
                    self.props.showNoty('tr', 'error', 'Zlecenie o podanym numerze nie zostało znalezione');
                } else {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisu danych');
                }
                self.setState({ isLoading: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        const form = (
            <div>

                <FormGroup>
                    <ControlLabel>Reklamacja dotyczy:</ControlLabel>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={12} md={6} style={{ marginBottom: 5 }}>
                            <Select
                                className="react-select primary react-select-intable"
                                classNamePrefix="react-select"
                                placeholder="Wybierz.."
                                value={this.state.regardingTo}
                                options={this.regardingToOptions}
                                onChange={(ev) => { this.setState({ regardingTo: ev }) }}
                                isClearable={false}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Numer zlecenia`}
                                value={this.state.orderIdent || ""}
                                required
                                onChange={(e) => { this.setState({ orderIdent: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                placeholder={`Nazwa towaru`}
                                value={this.state.goodsName || ""}
                                onChange={(e) => { this.setState({ goodsName: e.target.value }) }}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="number"
                                placeholder={`Wartość reklamacji (zł)`}
                                value={this.state.value || ""}
                                required
                                onChange={(e) => { this.setState({ value: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Osoba zgłaszająca:</ControlLabel>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                required
                                placeholder={`Imię`}
                                value={this.state.firstName || ""}
                                onChange={(e) => { this.setState({ firstName: e.target.value }) }}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="text"
                                required
                                placeholder={`Nazwisko`}
                                value={this.state.lastName || ""}
                                onChange={(e) => { this.setState({ lastName: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="email"
                                required
                                placeholder={`E-mail`}
                                value={this.state.emailAddress || ""}
                                onChange={(e) => { this.setState({ emailAddress: e.target.value }) }}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <FormControl
                                style={{ marginBottom: "5px" }}
                                type="number"
                                required
                                placeholder={`Telefon kontaktowy`}
                                value={this.state.mobilePhone || ""}
                                onChange={(e) => { this.setState({ mobilePhone: e.target.value }) }}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Opis zgłoszenia:</ControlLabel>
                </FormGroup>

                <FormGroup>
                    <textarea
                        className="form-control"
                        value={this.state.comment}
                        required
                        placeholder="Wprowadź opis zgłoszenia"
                        onChange={event =>
                            this.setState({ comment: event.target.value })
                        }
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Załączniki:</ControlLabel>
                </FormGroup>

                <FormGroup>
                    <ExpertUploadMultipleComponent howMuch={3} onSelectedFiles={files => { this.setState({ additionalFiles: files }) }} />
                </FormGroup>

                <FormGroup>
                    <Checkbox
                        number={31}
                        label={"Wyrażam zgodę na przetwarzanie moich danych osobowych przez Expert Sp. z o. o. w celu realizacji zgłoszenia reklamacji i przeprowadzenia ankiety badania satysfakcji z naszych usług za pośrednictwem telefonu zgodnie z art. 172 ustawą z dnia 16 lipca 2004 r. - Prawo telekomunikacyjne (t.j. Dz. U. z 2017 r. poz. 1907 z późn.zm.)"}
                        checked={this.state.consent1}
                        required
                        onChange={event => {
                            this.setState({ consent1: event.target.checked });
                        }}
                    />
                </FormGroup>

            </div>
        );

        const orderPage = (
            <>
                <form onSubmit={(event) => { this.onSubmit(event); }}>
                    {form}

                    <Row style={{ marginTop: "30px" }}>
                        <Col xs={12} style={{ textAlign: "center" }}>
                            <Button disabled={this.state.isLoading} bsStyle="default" fill wd icon type="submit">
                                <>Wyślij zgłoszenie</>
                            </Button>
                        </Col>
                    </Row>

                </form>
            </>
        );

        const thankYouPage = (
            <div className="push-30">
                <h2 className="text-center" style={{ marginTop: "30vh", marginBottom: "30px" }}>Dziękujemy!</h2>
                <p className="lead" style={{ padding: "20px" }}>Twoja reklamacja została przyjęta. Nasi pracownicy skontaktują się z&nbsp;Tobą w&nbsp;celu ustalenia dalszych szczegółów.</p>
                <p className="text-right lead" style={{ padding: "20px" }}><i>Zespół Expert-Transport</i></p>
            </div>
        );

        const fullStructure = (
            <Row>
                <Col smHidden md={6} style={{ padding: 0, backgroundImage: "url(" + bgImage + ")", backgroundPosition: "center center", backgroundSize: "cover", height: "100%", minHeight: "100vh", position: "fixed" }}>
                </Col>
                <Col xs={12} md={6} mdPush={6} style={{ paddingBottom: "60px" }}>
                    <Row>
                        <Col mdPush={1} md={10}>


                            {
                                this.state.isSubmitted
                                    ?
                                    thankYouPage
                                    :
                                    (
                                        <>
                                            <h4 className="text-center" style={{ marginBottom: "30px" }}>
                                                Formularz reklamacji towaru lub&nbsp;usługi
                                            </h4>
                                            <FormGroup className="push-60 line-accross" />
                                            {orderPage}
                                        </>
                                    )

                            }
                        </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #ddd", paddingTop: "20px", marginTop: "60px" }}>
                        <Col mdPush={2} md={8}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><a href="http://expert-transport.pl/"><img src={logo} alt="Expert - Zlecenia" style={{ maxWidth: "100px" }} /></a></td>
                                        <td style={{ paddingLeft: "20px" }}>
                                            <p className="light">
                                                Za transport Twoich mebli odpowiada firma Expert. W&nbsp;razie pytań zadzwoń: <a href="tel:+48585008501">+48 58 500 85 01</a> lub napisz <a href={`mailto:reklamacje@expert-transport.pl`}>{`reklamacje@expert-transport.pl`}</a>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );

        return (
            <div className="main-content">
                <Grid fluid>
                    {fullStructure}
                </Grid>
            </div>
        );
    }

}

export default ExpertForm;
