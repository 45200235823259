import ExpertForm from "views/Expert/Form/Form.jsx"

var routes = [
    {
        layout: "/",
        path: "/:orderIdent?",
        name: "",
        component: ExpertForm
    }
];
export default routes;
