import React, { Component } from "react";

class ExpertUploadMultipleComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFiles0: [],
            selectedFiles1: [],
            selectedFiles2: [],
            selectedFiles3: [],
            selectedFiles4: [],
            howMuch: props.howMuch
        }
    }

    onChangeHandler = (event, index) => {
        this.setState({
            ["selectedFiles" + index]: event.target.files,
        }, () => this.props.onSelectedFiles([...this.state.selectedFiles0, ...this.state.selectedFiles1, ...this.state.selectedFiles2, ...this.state.selectedFiles3, ...this.state.selectedFiles4]))
    }

    render() {

        let list = [];
        for (let i = 0; i < this.state.howMuch; i++) {
            list.push(
                <div className="custom-file" key={"fileUpload" + i} style={{ marginBottom: "15px" }}>
                    <input type="file" className="custom-file-input" onChange={(event) => { this.onChangeHandler(event, i) }} id={"customFile" + i} />
                    <label className="custom-file-label" htmlFor={"customFile" + i}>{this.state[`selectedFiles${i}`].length ? this.state[`selectedFiles${i}`][0].name : "Wybierz plik"}</label>
                </div>
            );
        }

        return list;
    }

}

export default ExpertUploadMultipleComponent;