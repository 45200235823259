/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import 'react-app-polyfill/ie9';
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import * as Sentry from '@sentry/browser';

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";

import OrderLayout from "layouts/Order.jsx";

Sentry.init({ dsn: "https://af372cf4e81e4baca22da92de775ca52@sentry.io/1881128" });

ReactDOM.render(
    <HashRouter>
        <Switch>
            <Route path="/" render={props => <OrderLayout {...props} />} />
        </Switch>
    </HashRouter>,
    document.getElementById("root")
);
